* {
  font-family: "Space Grotesk", sans-serif;
  overflow: hidden;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
#root {
  .App {
    animation: fade-in 3s;
    margin: 0 auto;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 100vh;
    @media (max-width: 1000px) {
      padding: 0;
    }

    h1 {
      font-size: 40px;

      @media (max-width: 1000px) {
        font-size: 45px;
      }
      @media (max-width: 800px) {
        font-size: 38px;
      }
      @media (max-width: 650px) {
        font-size: 30px;
      }

      @media (max-width: 550px) {
        font-size: 30px;
      }
      @media (max-width: 450px) {
        font-size: 25px;
      }
      @media (max-width: 350px) {
        font-size: 20px;
      }
      @media (max-width: 300px) {
        font-size: 15px;
      }
    }

    .app__img {
      display: flex;
      position: relative;

      justify-content: center;
      margin: 0 auto;
      max-width: 440px;

      // max-width: 1100px;
      .logo {
        //  : 81%;

        margin-right: 20px;
        @media (max-width: 1000px) {
          width: 400px;
        }
        @media (max-width: 800px) {
          width: 350px;
        }
        @media (max-width: 650px) {
          width: 250px;
        }

        @media (max-width: 550px) {
          width: 250px;
          margin-right: 13px;
        }
        @media (max-width: 450px) {
          width: 200px;
          margin-right: 10px;
        }
        @media (max-width: 350px) {
          width: 180px;
          margin-right: 8px;
        }
        @media (max-width: 300px) {
          width: 120px;
          margin-right: 7px;
        }
      }

      .dot-container {
        align-items: flex-end;
        display: flex;
      }

      .dot1 {
        width: 62px;
        height: auto;

        animation: dot1FadeInOut 2s ease-in-out infinite;
        opacity: 0;
      }

      .dot2 {
        width: 62px;
        height: auto;
        //   transition-delay: 0.2s;

        animation: dot2FadeInOut 2s ease-in-out infinite;
        opacity: 0;
        padding-left: 20px;
        @media (max-width: 550px) {
          padding-left: 13px;
        }
        @media (max-width: 450px) {
          padding-left: 10px;
        }
        @media (max-width: 350px) {
          padding-left: 8px;
        }
      }
      .dot1,
      .dot2 {
        padding-bottom: 1px;
        @media (max-width: 1000px) {
          width: 34px;
          padding-bottom: 1px;
        }
        @media (max-width: 800px) {
          width: 28px;
        }
        @media (max-width: 650px) {
          width: 20px;
          padding-bottom: 0;
        }

        @media (max-width: 550px) {
          width: 20px;
        }
        @media (max-width: 450px) {
          width: 16px;
        }
        @media (max-width: 350px) {
          width: 15px;
          padding-bottom: 0;
        }
        @media (max-width: 300px) {
          width: 10px;
        }
      }
    }
  }

  @keyframes dot1FadeInOut {
    0% {
      opacity: 0;
    }
    10% {
      opacity: 1;
    }
    45% {
      opacity: 1;
    }
    55% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }

  @keyframes dot2FadeInOut {
    0% {
      opacity: 0;
    }
    45% {
      opacity: 0;
    }
    55% {
      opacity: 1;
    }
    90% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  .dot1,
  .dot2 {
    animation-delay: 0s, 2s; /* Добавляем задержку для синхронной повторной анимации */
  }
  .App.animation-started .dot {
    opacity: 1;
  }
}
